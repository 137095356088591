<template>
  <li class="items">
    <div class="info_img">
      <img :src="item.photo" alt="" />
    </div>
    <div class="info_text">
      <a href="#">
        <h5>{{ item.name }}</h5>
        <p>{{ item.description }}</p>
      </a>
      <a :href="item.link" class="learn_more">Learn more</a>
    </div>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
}
</script>

<style lang="scss" scoped>
.info_img {
  img {
    height: 100%;
    width: 100%;
    display: block;
    max-height: 150px;
  }
}
</style>
