<template>
  <main class="home">
    <div class="main-slider">
      <v-carousel
        interval="6000"
        cycle
        style="position: relative"
        class="slider carousel"
        hide-delimiters
        :show-arrows="true"
        v-model="activeCarouselItem"
      >
        <v-carousel-item v-for="(item, i) in mainCarouselBanners" :key="i">
          <div
            class="slider__content pa-6"
            :class="item?.position.split(' ')[1]"
          >
            <div
              class="slider_text"
              :class="item?.position.split(' ')[0]"
              :key="item?.position"
            >
              <p>
                <span class="d-block">
                  {{ item?.name }}
                </span>
                <b class="d-block">
                  {{ item?.description }}
                </b>
              </p>
              <button>
                <a :href="item?.link">
                  {{ item?.ext_inf_2 }}
                </a>
              </button>
            </div>
            <div class="slider_dots">
              <ul class="pa-0">
                <li
                  v-for="(item, index) in mainCarouselBanners.length"
                  :key="index"
                  class="dots"
                  :class="{ dots_active: index === activeCarouselItem }"
                  @click="activeCarouselItem = index"
                ></li>
              </ul>
            </div>
          </div>
          <div style="max-height: 700px">
            <img
              style="width: 100%; height: 100%"
              :src="item.photo"
              :alt="item.ext_inf_1"
            />
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <div class="container py-0">
      <section class="category mb-7">
        <v-container fluid class="pa-0">
          <v-row>
            <v-col
              @click="relocateToCategoryBanner(item.link)"
              cols="3"
              v-for="item in categoryBanners"
              :key="item.id"
            >
              <CategoryCard
                :text="item.name"
                :imgSrc="item.photo"
                class="collections_img"
              >
                <!-- :imgSrc="item.photo" -->
                <!-- @click.native="
                        $router.push({
                          name: 'Categories',
                          query: { section: item.section },
                        })
                      " -->
                <template #title>
                  <div
                    @click="getProductsByCategory(item)"
                    class="c_text bordered"
                  >
                    <span>{{ item.name }}</span>
                  </div>
                </template>
              </CategoryCard>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="product_info my-2">
        <ul class="d-flex justify-content-center info_item pa-0">
          <FeatureCard
            v-for="item in infoBlockBanners"
            :key="item.id"
            :item="item"
          />
        </ul>
      </section>

      <section class="products d-block mt-2">
        <div class="title_product">
          <h2 :class="{ 'mb-3': $vuetify.breakpoint.smAndDown }">
            Our Popular Goods
          </h2>
          <!-- <ul> -->
          <v-tabs
            hide-slider
            v-model="tab"
            :show-arrows="false"
            :class="{ 'mb-6': $vuetify.breakpoint.smAndDown }"
          >
            <v-tab
              v-for="item in retailCategories"
              :key="item.id"
              :class="{ 'px-10': $vuetify.breakpoint.mdAndUp }"
              :ripple="false"
              @click="getProductsByCategory(item)"
            >
              {{ item.title?.ru }}
            </v-tab>
            <!-- <v-tab
              :class="{ 'px-10': $vuetify.breakpoint.mdAndUp }"
              :ripple="false"
            >
              Interior
            </v-tab>
            <v-tab
              :class="{ 'px-10': $vuetify.breakpoint.mdAndUp }"
              :ripple="false"
            >
              Accessories
            </v-tab> -->
          </v-tabs>
        </div>
        <v-tabs-items v-model="tab">
          <div class="pa-0 w-100">
            <transition name="slide-fade" mode="out-in">
              <v-row key="1" class="list_product mx-n3">
                <v-col
                  v-for="product in products"
                  :key="product.product.id"
                  cols="6"
                  md="4"
                  class="pa-3"
                >
                  <ProductCard
                    :product="product.product"
                    :price="product.price"
                    :currency="product.currency"
                    @click.native="
                      () => {
                        $router.push({
                          name: 'Product',
                          params: { id: product.product.slug },
                        })
                        setSelectedProduct(product.product)
                      }
                    "
                    @goToDetails="
                      () => {
                        $router.push({
                          name: 'Product',
                          params: { id: product.product.slug },
                        })
                        setSelectedProduct(product.product)
                      }
                    "
                  />
                </v-col>
              </v-row>
            </transition>
          </div>
        </v-tabs-items>
      </section>

      <section
        v-if="mainBanner1.length"
        class="banner"
        :class="mainBanner1[0].position.split(' ')[1]"
        :style="{
          backgroundImage: `url('${mainBanner1[0].photo}')`,
        }"
      >
        <div
          class="b_text d-flex flex-column"
          :class="mainBanner1[0].position.split(' ')[0]"
        >
          <p
            style="color: #fff"
            :style="{
              textAlign: mainBanner1[0].position.includes('align-self-start')
                ? 'left'
                : mainBanner1[0].position.includes('align-self-center')
                ? 'center'
                : 'left',
            }"
            class="mb-2"
          >
            <span
              v-if="mainBanner1[0].name"
              v-html="mainBanner1[0].name"
              class="d-block"
            ></span>
            <span
              v-if="mainBanner1[0].description"
              v-html="mainBanner1[0].description"
              class="d-block"
            ></span>
          </p>
          <button
            v-if="mainBanner1[0].ext_inf_2"
            class="btn_text"
            :class="mainBanner1[0].position.split(' ')[0]"
          >
            <a :href="mainBanner1[0].link">{{ mainBanner1[0].ext_inf_2 }}</a>
          </button>
        </div>
      </section>
    </div>

    <v-container
      tag="section"
      class="collections our_blog my-2 container"
      :class="{ 'pa-0 mb-12': $vuetify.breakpoint.smAndDown }"
    >
      <div class="d-flex py-4 align-center">
        <h3 class="ma-0 pa-0">Our collections</h3>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          style="height: fit-content"
          class="pa-0"
          :ripple="false"
          @click="$router.push({ name: 'Blogs' })"
        >
        </v-btn>
      </div>
      <swiper
        class="mySwiper"
        :navigation="true"
        :slides-per-view="3"
        :space-between="0"
        :centeredSlides="true"
        v-if="collections.length"
      >
        <swiper-slide
          v-for="(collection, index) in collections"
          :key="collection?.id"
          @click="openBlog(collection)"
          class="px-2 cursor-pointer"
        >
          <CategoryCard
            :imgSrc="collection?.photo"
            class="collections_img"
            :class="{
              'mr-n2':
                index === collection.length - 1 && $vuetify.breakpoint.mdAndUp,
            }"
          >
            <template #title>
              <div class="collections_text">
                <span>{{ collection?.title }}</span>
              </div>
            </template>
          </CategoryCard>
        </swiper-slide>
      </swiper>
    </v-container>

    <section v-if="mainBanner2.length" class="container">
      <div
        class="banner_2 my-3"
        :class="mainBanner2[0].position.split(' ')[1]"
        :style="{
          backgroundImage: `url('${mainBanner2[0].photo}')`,
        }"
      >
        <div
          class="b_text d-flex flex-column"
          :class="mainBanner2[0].position.split(' ')[0]"
        >
          <p
            :style="{
              textAlign: mainBanner2[0].position.includes('align-self-start')
                ? 'left'
                : mainBanner2[0].position.includes('align-self-center')
                ? 'center'
                : 'right',
            }"
            class="mb-2"
          >
            <span
              v-if="mainBanner1[0].name"
              v-html="mainBanner1[0].name"
            ></span>
            <span
              v-if="mainBanner1[0].description"
              v-html="mainBanner1[0].description"
            ></span>
            <span
              v-if="mainBanner1[0].ext_inf_1"
              v-html="mainBanner1[0].ext_inf_1"
            ></span>
          </p>
          <button
            v-if="mainBanner2[0].ext_inf_2"
            class="btn_text"
            :class="mainBanner2[0].position.split(' ')[0]"
          >
            <a :href="mainBanner2[0].link">
              {{ mainBanner2[0].ext_inf_2 }}
            </a>
          </button>
        </div>
      </div>
    </section>

    <v-container
      tag="section"
      class="collections our_blog my-2 container"
      :class="{ 'pa-0 mb-12': $vuetify.breakpoint.smAndDown }"
    >
      <div class="d-flex py-4 align-center">
        <h3 class="ma-0 pa-0">Our blog</h3>
        <v-spacer></v-spacer>
        <v-btn
          text
          small
          style="height: fit-content"
          class="pa-0"
          :ripple="false"
          @click="$router.push({ name: 'Blogs' })"
        >
          Все статьи
        </v-btn>
      </div>
      <VueSlickCarousel
        ref="slick"
        class="full-width-carousel"
        v-bind="settings"
      >
        <div
          v-for="(blog, index) in blogs"
          :key="blog?.id"
          @click="openBlog(blog)"
          class="loop pa-2 cursor-pointer"
        >
          <CategoryCard
            :imgSrc="blog?.photo"
            class="collections_img"
            :class="{
              'mr-n2':
                index === blogs.length - 1 && $vuetify.breakpoint.mdAndUp,
            }"
          >
            <template #title>
              <div class="collections_text">
                <span>{{ blog?.title }}</span>
              </div>
            </template>
          </CategoryCard>
        </div>
        <template #prevArrow>
          <div class="custom-prev-arrow" @click="prevSlide">Previous</div>
        </template>
        <template #nextArrow>
          <div class="custom-next-arrow" @click="nextSlide">Next</div>
        </template>
      </VueSlickCarousel>
      <!-- <swiper
        class="mySwiper"
        :navigation="true"
        :slides-per-view="5"
        :space-between="0"
        :centeredSlides="true"
        v-if="blogs.length"
      >
        <swiper-slide
          v-for="(blog, index) in blogs"
          :key="blog?.id"
          @click="openBlog(blog)"
          class="px-2 cursor-pointer"
        >
          <CategoryCard
            :imgSrc="blog?.photo"
            class="collections_img"
            :class="{
              'mr-n2':
                index === blogs.length - 1 && $vuetify.breakpoint.mdAndUp,
            }"
          >
            <template #title>
              <div class="collections_text">
                <span>{{ blog?.title }}</span>
              </div>
            </template>
          </CategoryCard>
        </swiper-slide>
      </swiper> -->
    </v-container>
  </main>
</template>

<!-- <ProductCard
  :product="product"
  @click.native="
    () => {
      $router.push({
        name: 'Product',
        params: { id: product.id },
      })
      setSelectedProduct(product)
    }
  "
  @click="addToCart(product)"
/> -->

<script>
import { createNamespacedHelpers } from 'vuex'
import cloneDeep from 'lodash.clonedeep'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import CategoryCard from '@/view/content/store/CategoryCard.vue'
import FeatureCard from '@/view/content/store/FeatureCard.vue'
import ProductCard from '@/view/content/store/ProductCard.vue'

import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'

// Import Swiper styles
SwiperCore.use([Navigation])
import 'swiper/swiper-bundle.css'

const { mapState, mapActions } = createNamespacedHelpers('storeCart')
const { mapState: State_blogs, mapActions: Actions_blogs } =
  createNamespacedHelpers('blogs')
const { mapState: mapCategoriesState, mapActions: mapCategoriesActions } =
  createNamespacedHelpers('blogCategories')
const {
  mapState: State_products,
  mapActions: Actions_products,
  mapMutations: Mutations_products,
} = createNamespacedHelpers('products')
const { mapState: State_banners, mapActions: Actions_banners } =
  createNamespacedHelpers('banners')
const { mapState: State_categories, mapActions: Actions_categories } =
  createNamespacedHelpers('retailCategories')
const { mapState: Actions_auth } = createNamespacedHelpers('auth')
export default {
  name: 'Home',
  components: {
    CategoryCard,
    FeatureCard,
    ProductCard,
    Swiper,
    SwiperSlide,
    VueSlickCarousel,
  },
  data() {
    return {
      activeCarouselItem: 0,
      tab: 0,
      items: ['FELT SHOES', 'INTERIOR', 'ACCESSORIES'],
      carouselItems: [
        '/img/img_main/slider/photo1.png',
        '/img/img_main/slider/photo2.png',
        '/img/img_main/slider/photo1.png',
      ],
      carouselSettings: {
        // настройки карусели Hooper
        // например, количество отображаемых элементов
        itemsToShow: 3,
      },
      settings: {
        dots: false,
        swipeToSlide: true,
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
      },
      categories: [
        {
          imgSrc: '/img/img_main/banner_1/Rectangle137.png',
          text: 'FELT SHOES',
          section: 'felt shoes',
          id: 1,
        },
        {
          imgSrc: '/img/img_main/banner_1/Rectangle_137.png',
          text: 'INTERIOR',
          section: 'interior',
          id: 2,
        },
        {
          imgSrc: '/img/img_main/banner_1/Rectangle138.png',
          text: 'ACCESSORIES',
          section: 'accessories',
          id: 3,
        },
      ],
      features: [
        {
          class: 'hand_i',
          title: 'Materials & Production',
          text: 'Find out about production, materials and biodegradability',
        },
        {
          class: 'basket_i',
          title: 'Zero-waste Management',
          text: 'We repurpose wool waste into sustainable wall panels',
        },
        {
          class: 'shoes_i',
          title: 'Compost Old Shoes',
          text: 'Return the soil back to earth by composting worn out shoes',
        },
        {
          class: 'h_hand_i',
          title: 'Health Benefits',
          text: 'Click and learn about how we produce shoes in wet-felting technology',
        },
      ],

      activeCarouselSlide: null,
    }
  },
  computed: {
    ...mapState(['isSideCartVisible']),
    ...State_products(['mainProducts', 'products', 'allProducts']),
    ...State_banners(['allBanners']),
    ...State_blogs(['blogs', 'collections']),
    ...mapCategoriesState(['blogCategories']),
    ...State_categories(['retailCategories']),
    categoryBanners() {
      return this.allBanners.filter((el) => el.code === 'categories')
    },
    infoBlockBanners() {
      return this.allBanners.filter((el) => el.code === 'info-block')
    },
    mainBanner1() {
      return this.allBanners.filter((el) => el.code === 'main-banner-1')
    },
    mainBanner2() {
      return this.allBanners.filter((el) => el.code === 'main-banner-2')
    },
    mainCarouselBanners() {
      return this.allBanners.filter((el) => el.code === 'main-carousel')
    },
  },
  watch: {
    activeCarouselItem(val) {
      this.activeCarouselSlide = this.mainCarouselBanners[val]
    },
    mainCarousel(val) {
      this.activeCarouselSlide = val[this.activeCarouselItem]
    },
  },
  methods: {
    ...mapActions(['toggleSideCart', 'addCartItem', 'setSelectedProduct']),
    ...Actions_banners(['fetchAllBanners']),
    ...Actions_auth(['fetchLocales']),
    ...Actions_categories(['getAllRetailCategories']),
    ...mapCategoriesActions(['fetchBlogCategories', 'fetchBlogCategoryByUrl']),
    ...Actions_blogs(['fetchBlogs', 'fetchBlogByUrl']),
    ...Actions_products([
      'getProductsMainPage',
      'getAllProducts',
      'productByCatigory',
    ]),
    ...Mutations_products(['CLEAN_PRODUCTS']),
    prevSlide() {
      this.$refs.slick.prev()
    },
    nextSlide() {
      this.$refs.slick.next()
    },
    relocateToCategoryBanner(url) {
      window.open(window.location.origin + `/${url}`, '_blank')
    },
    addToCart(item) {
      const copy = cloneDeep(item)
      copy.colors = copy.colors[0]
      copy.colors.sizes = [copy.colors.sizes[0]]
      this.addCartItem(copy)
      this.toggleSideCart(true)
    },
    getProductsByCategory(category) {
      this.CLEAN_PRODUCTS()
      this.productByCatigory(category)
    },
    openBlog(blog) {
      this.$router.push({
        name: 'Generated Blog',
        params: { category: 'category', url: blog.url },
      })
    },
  },
  created() {
    this.fetchAllBanners()
    this.getProductsMainPage()
    this.getAllProducts()
    this.fetchBlogCategories()
    this.blogCategories?.forEach((item) => {
      this.fetchBlogs({ category: item })
    })
    this.getAllRetailCategories()
    this.productByCatigory({
      id: 3,
    })
    this.fetchLocales()
  },
}
</script>
<style lang="scss">
button.btn_text.align-self-end {
  align-self: flex-start !important;
}
button.slick-prev:before,
button.slick-next:before {
  // background: #000 !important;
  font-size: 30px;
  width: 40px;
  height: 40px;
}

.full-width-carousel {
  .slick-arrow {
    z-index: 2;
  }
}
.carousel-container {
  width: 1280px; /* Установите ширину контейнера на всю ширину сайта */
}

.custom-prev-arrow,
.custom-next-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
}

.slick-list {
  width: 1280px;
  overflow: visible !important;
}
.slick-track {
  direction: ltr !important;
}
section.banner,
.banner_2 {
  min-height: 270px;
  padding: 20px 90px;
}

section.category .image__wrap img {
  object-fit: cover;
}

section.product_info .info_img img {
  object-fit: contain;
  width: 63px;
  height: 60px;
  margin: 0 auto;
}

.title_product {
  .v-slide-group__content {
    flex: unset;
    margin: 0 auto;
  }
  .v-tabs-bar {
    max-height: 36px;
  }
}
.carousel {
  // height: unset !important;
  .v-carousel__item {
    height: unset !important;
  }
}
</style>
<style lang="scss" scoped>
.swiper {
  width: 1440px !important;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: inline-block;
}

// .swiper-slide img {
//   display: block;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// }

// .swiper {
//   width: 100%;
//   height: 300px;
//   margin: 20px auto;
// }
.main-slider .v-window.slider.carousel.v-item-group.theme--dark.v-carousel {
  height: 700px !important;
}

.carousel,
.v-tab {
  text-transform: none;
  &:not(:last-child) {
    border-right: 1px solid #e6e6e6;
  }
  &.v-tab {
    color: #9a9a9a !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.07em;
  }
  &.v-tab--active {
    color: #423f3d !important;
  }
}
.slider__content {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
}
.collections_img {
  cursor: pointer;
}
.c_text span {
  color: #666666;
  font-size: 0.875rem;
  font-weight: 500;
}

.list_product {
  margin: 0 -10px 0 -10px;
}
.list_product-item {
  padding: 10px;
}
.our_blog {
  h3 {
    font-size: 1.125rem;
    color: #423f3d;
    font-weight: 500;
    text-transform: capitalize;
  }
  .v-btn {
    &:hover {
      text-decoration: underline;
    }
    &:before {
      background-color: transparent !important;
    }
  }
}
.slider.carousel p h1 {
  font-size: 46px;
  color: white;
  margin-bottom: 29px;
  font-weight: bold;
}
.slider.carousel p,
.slider.carousel p h1 ~ p,
.slider.carousel p strong {
  color: white;
  font-size: 16px;
  display: inline-block;
}
.slider.carousel p h1 ~ p {
  height: 60px;
}

.slider.carousel button {
  margin-top: 3px;
}
</style>
